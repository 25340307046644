<template>
    <v-dialog :value="true" persistent max-width="600px">
        <v-card light>
            <v-card-title class="justify-center">
                <v-spacer/>
                <span class="text-h5" style="word-break: break-word;">
                    {{ edit ? 'Edição' : 'Emissão' }} de assinatura
                </span>
                <v-spacer/>
            </v-card-title>
            <v-card-text align="center" justify="center">
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-select
                            :items="planos"
                            v-model="form.plano"
                            outlined
                            dense
                            :item-text="e => `${e.name} (R$ ${(e.value / 100).toFixed(2)})`"
                            item-value="id"
                            label="Planos"
                            hide-details
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-select
                            :items="formasPagamento"
                            v-model="form.formaPagamento"
                            outlined
                            dense
                            label="Forma de pagamento"
                            hide-details
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col v-if="form.formaPagamento === 'bolix'">
                        <v-text-field
                            label="Vencimento (mínimo D+1)"
                            type="date"
                            v-model="form.vencimento"
                            outlined
                            dense
                            hide-details
                        />
                    </v-col>
                    <v-col v-if="form.formaPagamento === 'cartao'">
                        <v-text-field
                            label="Data de cobrança"
                            type="date"
                            v-model="form.dataCobranca"
                            outlined
                            dense
                            hide-details
                        />
                    </v-col>
                </v-row>
                <v-checkbox
                    v-if="form.formaPagamento === 'bolix'"
                    v-model="form.alterarDadosAssinatura"
                    label="Alterar dados do assinante"
                />
                <v-btn-toggle
                    v-if="form.formaPagamento === 'bolix' && form.alterarDadosAssinatura"
                    v-model="form.tipoPessoa"
                    rounded
                    color="primary"
                >
                    <v-btn value="pf">
                        Pessoa Física
                    </v-btn>
                    <v-btn value="pj">
                        Pessoa Jurídica
                    </v-btn>
                </v-btn-toggle>
                <v-form
                    v-if="form.tipoPessoa && form.formaPagamento === 'bolix' && form.alterarDadosAssinatura"
                    ref="form"
                    class="mt-2"
                >
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-if="form.tipoPessoa === 'pf'"
                                label="Nome Completo"
                                v-model="form.nome"
                                :rules="[rules.empty, rules.fullName]"
                                outlined
                                dense
                                hide-details
                            />
                            <v-text-field
                                v-else
                                label="Razão Social"
                                v-model="form.corporateName"
                                :rules="[rules.empty]"
                                outlined
                                dense
                                hide-details
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                            label="E-mail"
                            v-model="form.email"
                            :rules="[rules.email]"
                            outlined
                            dense
                            hide-details
                        />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-if="form.tipoPessoa === 'pf'"
                                label="CPF"
                                v-model="form.cpf"
                                :rules="[rules.empty, rules.cpf]"
                                v-mask="['###.###.###-##']"
                                outlined
                                dense
                                hide-details
                            />
                            <v-text-field
                                v-else
                                label="CNPJ"
                                v-model="form.cnpj"
                                :rules="[rules.empty, rules.cnpj]"
                                v-mask="['##.###.###/####-##']"
                                outlined
                                dense
                                hide-details
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                label="Whatsapp"
                                v-model="form.whatsapp"
                                :rules="[rules.empty]"
                                prefix="+55"
                                placeholder="(99) 99999-9999"
                                v-mask="['(##) ####-####', '(##) #####-####']"
                                outlined
                                dense
                                hide-details
                            />
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn text color="warning" @click="$emit('close')">Cancelar</v-btn>
                <v-spacer/>
                <v-btn color="success" @click="confirmar">Confirmar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'EmissaoAssinatura',

    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        edit: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [String, Number],
            default: '',
        },
    },

    data: () => ({
        form: {
            tipoPessoa: null,
            alterarDadosAssinatura: false,
        },
        planos: [],
        formasPagamento: [
            {
                value: 'bolix',
                text: 'Boleto/Pix',
            },
            {
                value: 'cartao',
                text: 'Cartão de Crédito',
            },
        ],
        rules: {
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'E-mail inválido'
            },

            empty: value => !!value || 'Preenchimento obrigatório',

            fullName: value => (value || '').trim().split(' ').length > 1 || 'Informe o nome completo',

            cpf: value => value?.length == 14 || 'Cpf incompleto',
        },
    }),

    mounted() {
        this.getPlanos();
        this.form.vencimento = this.moment().add(1, 'days').format('YYYY-MM-DD');
    },

    methods: {
        getPlanos() {
            this.$http.post('empresa/get-planos').then(resp => {
                this.planos = resp.data.data;
            });
        },

        confirmar() {
            if (!this.form.formaPagamento || !this.form.plano) {
                alert('Preencha todos os campos');
                return;
            }

            if (this.form.formaPagamento == 'bolix' && this.form.alterarDadosAssinatura && !this.$refs.form.validate()) {
                alert('Preencha todos os campos');
                return;
            }

            const data = {
                ...this.form,
                ...{ empresa: this.value },
            }
            this.$root.$emit('loading', true);
            this.$http.post(`assinaturas/${this.edit ? 'editar' : 'emitir'}`, data).then(resp => {
                if (resp.data?.type === 'warning') {
                    alert(resp.data.msg)
                    return;
                }

                this.$emit('confirm', resp.data);
            })
            .catch(() => {
                alert('Não foi possivel concluir a operação', 'warning');
            }).finally(() => (this.$root.$emit('loading', false)));
        },
    },
}
</script>

<style scoped>
.v-card {
    padding: 0 15px 0 15px;
}
</style>
