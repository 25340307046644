<template>
    <BaseContainer>
        <span slot="principal">
            <v-content class="pr-2 pl-2" style="background-color: #555;">
                <v-toolbar class="ma-2" style="border-radius: 30px;">
                    <v-btn icon link to="/">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer/>
                    <v-toolbar-title v-show="$vuetify.breakpoint.smAndUp">Empresas</v-toolbar-title>
                    <v-spacer/>
                    <v-text-field
                        @keypress.enter="consultar"
                        dense
                        outlined
                        single-line
                        hide-details
                        v-model="search"
                        label="Pesquise.."
                        append-icon="mdi-magnify"
                    />
                    <v-spacer/>
                    <v-checkbox
                        v-model="somenteInadimplentes"
                        label="Listar somente inadimplentes"
                        hide-details
                    />
                    <v-spacer/>
                    <PeriodoFilter
                        v-model="periodo"
                        @update="consultar"
                    />
                    <!-- <v-btn color="primary" fab small @click="novo">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn> -->
                </v-toolbar>
                <v-card elevation="2" class="ma-1">
                    <v-card-text>
                        <v-data-table
                            fixed-header
                            :headers="headers"
                            :items="dados"
                            :mobileBreakpoint="0"
                            :loading="loading"
                            :height="$vuetify.breakpoint.height - 230"
                            :disable-pagination="true"
                            :hide-default-footer="true"
                        >
                            <template v-slot:item.database="{ item }">
                                {{ `emp_${item.database}` }}
                            </template>
                            <template v-slot:item.whatsapp="{ item }">
                                <div @click="abrirWhatsapp(item.whatsapp)" style="cursor: pointer;">
                                    <v-icon color="green" small>mdi-whatsapp</v-icon>&nbsp;
                                    <u>{{ item.whatsapp }}</u>
                                </div>
                            </template>
                            <template v-slot:item.created_at="{ item }">
                                {{ formatDate(item.created_at) }}
                            </template>
                            <template v-slot:item.dominio="{ item }">
                               <div @click="abrirCardapio(item.dominio)" style="cursor: pointer;">
                                    <v-icon small>mdi-open-in-new</v-icon>&nbsp;
                                    <u>{{ item.dominio }}</u>
                                </div>
                            </template>
                            <template v-slot:item.assinatura_id="{ item }">
                                <v-icon
                                    :color="getSubscriptionColor(item)"
                                    small
                                >mdi-circle</v-icon>&nbsp;
                               <div @click="editar(item)" style="cursor: pointer;">
                                    <u>{{ item.assinatura_id }}</u>
                                </div>
                            </template>
                            <template v-slot:item.ativo="{ item }">
                                <v-chip class="ma-2" :color="item.ativo ? 'green' : 'red'" text-color="white">
                                    {{ item.ativo ? 'Ativo' : 'Inativo' }}
                                </v-chip>
                            </template>
                            <template v-slot:item.assinatura_emissao="{ item }">
                                {{ formatDate(item.assinatura_emissao) }}
                            </template>
                            <template v-slot:item.data_ultimo_pedido="{ item }">
                                <span :class="{ 'red-color': isDateOlderThan5Days(item) }">
                                    {{ formatDate(item.data_ultimo_pedido) }}
                                </span>
                            </template>
                            <template v-slot:item.trial_started_at="{ item }">
                                {{ formatDate(item.trial_started_at, 'DD/MM/YYYY') }}
                            </template>
                            <template v-slot:item.action="{ item }">
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon v-bind="attrs" v-on="on">
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                            v-if="checkPermission('zapercontrol_empresas_menu_modulos')"
                                            @click="openModulesDialog(item)"
                                        >
                                            <v-list-item-title>
                                                <v-icon>mdi-package-variant-plus</v-icon>&nbsp;
                                                Módulos
                                            </v-list-item-title>
                                        </v-list-item>

                                        <v-list-item
                                            v-if="checkPermission('zapercontrol_empresas_menu_iniciar_teste')"
                                            @click="testeGratisDialog(item.id)"
                                        >
                                            <v-list-item-title>
                                                <v-icon>mdi-flask</v-icon>&nbsp;
                                                Iniciar teste
                                            </v-list-item-title>
                                        </v-list-item>

                                        <v-list-item
                                            v-if="item.ativo && checkPermission('zapercontrol_empresas_menu_bloquear')"
                                            @click="bloquearDesbloquear(item.id)"
                                        >
                                            <v-list-item-title>
                                                <v-icon>mdi-lock</v-icon>&nbsp;
                                                Bloquear
                                            </v-list-item-title>
                                        </v-list-item>

                                        <v-list-item
                                            v-if="!item.ativo && checkPermission('zapercontrol_empresas_menu_desbloquear')"
                                            @click="bloquearDesbloquear(item.id)"
                                        >
                                            <v-list-item-title>
                                                <v-icon>mdi-lock-open</v-icon>&nbsp;
                                                Desbloquear
                                            </v-list-item-title>
                                        </v-list-item>

                                        <v-list-item
                                            v-if="item.assinatura_emissao && checkPermission('zapercontrol_empresas_menu_cancelamento')"
                                            @click="cancelarAssinatura(item.id)"
                                        >
                                            <v-list-item-title>
                                                <v-icon>mdi-cash</v-icon>&nbsp;
                                                Cancelamento
                                            </v-list-item-title>
                                        </v-list-item>

                                        <v-list-item
                                            v-if="!item.assinatura_emissao && checkPermission('zapercontrol_empresas_menu_emitir_assinatura')"
                                            @click="emitirAssinaturaDialog(item.id)"
                                        >
                                            <v-list-item-title>
                                                <v-icon>mdi-cash</v-icon>&nbsp;
                                                Emitir assinatura
                                            </v-list-item-title>
                                        </v-list-item>

                                        <v-list-item
                                            v-if="item.assinatura_emissao && checkPermission('zapercontrol_empresas_menu_alterar_assinatura')"
                                            @click="alterarAssinatura(item.id)"
                                        >
                                            <v-list-item-title>
                                                <v-icon>mdi-pencil</v-icon>&nbsp;
                                                Alterar assinatura
                                            </v-list-item-title>
                                        </v-list-item>

                                        <!-- <v-list-item @click="excluir(item.id)" style="color: red;">
                                            <v-list-item-title>
                                                <v-icon color="red">mdi-delete</v-icon>&nbsp;Excluir
                                            </v-list-item-title>
                                        </v-list-item> -->
                                    </v-list>
                                </v-menu>
                            </template>
                        </v-data-table>
                        <Paginate v-model="pagination" @input="consultar"/>
                    </v-card-text>
                </v-card>
                <EmissaoAssinatura
                    v-if="visible1"
                    :edit="edit"
                    v-model="empresaId"
                    @close="visible1 = false"
                    @confirm="emitirAssinatura"
                />
                <AssinaturaConcluida
                    v-model="cobranca"
                    :visible="visible2"
                    @confirm="visible2 = false"
                />
                <InicioTesteGratis
                    :visible="visible3"
                    v-model="empresaId"
                    @close="visible3 = false"
                    @confirm="confirmInicioTesteGratis"
                />
                <ModulosDialog
                    :visible="visible4"
                    v-model="empresa"
                    @close="visible4 = false"
                />
            </v-content>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';
import Paginate from '@/components/Paginate';
import PeriodoFilter from '@/components/PeriodoFilter';
import EmissaoAssinatura from './EmissaoAssinatura';
import AssinaturaConcluida from './AssinaturaConcluida';
import InicioTesteGratis from './InicioTesteGratis';
import ModulosDialog from './ModulosDialog';
import { mapState } from 'vuex';

export default {
    name: 'Empresas',

    components: {
        Paginate,
        BaseContainer,
        EmissaoAssinatura,
        AssinaturaConcluida,
        InicioTesteGratis,
        ModulosDialog,
        PeriodoFilter,
    },

    data: () => ({
        empresa: {},
        periodo: {},
        empresaId: '',
        search: '',
        edit: false,
        somenteInadimplentes: false,
        loading: false,
        visible1: false,
        visible2: false,
        visible3: false,
        visible4: false,
        dados: [],
        cobranca: {},
        pagination: {
            current_page: 1,
            last_page: 1,
            total: 0,
        },
        headers: [
            { text: 'ID', value: 'id' },
            { text: 'DB', value: 'database' },
            { text: 'Razao Social', value: 'razao_social' },
            { text: 'Whatsapp', value: 'whatsapp' },
            { text: 'Municipio', value: 'municipio' },
            { text: 'UF', value: 'uf' },
            { text: 'Cadastro', value: 'created_at' },
            { text: 'Dominio', value: 'dominio' },
            { text: 'Status', value: 'ativo' },
            { text: 'Ass. Emissão', value: 'assinatura_emissao' },
            { text: 'Teste Iniciado', value: 'trial_started_at' },
            { text: 'Últ. Pedido', value: 'data_ultimo_pedido' },
            { text: 'Forma Pgto', value: 'assinatura_forma_pagamento' },
            { text: 'Ass. Número', value: 'assinatura_id' },
            { text: 'Plano', value: 'plano_id' },
            { text: '', value: 'action', sortable: false, align: 'end'},
        ],
    }),

    computed: {
        ...mapState([
            'usuario',
        ]),

        isDiretor() {
            return this.usuario.tipo === 'diretor';
        },

        baseZaperMenu() {
            return (document.URL.indexOf('localhost') !== -1)
                ? 'http://localhost:8082'
                : 'https://app.zapermenu.com.br';
        },

        baseZaperComandas() {
            return (document.URL.indexOf('localhost') !== -1)
                ? 'http://localhost:8081'
                : 'https://admin.zapermenu.com.br';
        },
    },

    mounted() {
        this.getFilters();
        this.consultar();
    },

    methods: {
        getParams() {
            const params = new URLSearchParams();

            params.append('start', this.periodo.start);
            params.append('end', this.periodo.end);

            params.append('q', this.search || '');
            params.append('page', this.pagination.current_page);
            params.append('somenteInadimplentes', this.somenteInadimplentes);

            // if (this.options.sortBy.length) {
            //     params.append('sortField', this.options.sortBy[0]);
            // }
            // if (this.options.sortDesc.length) {
            //     params.append('sortOrder', this.options.sortDesc[0] ? 1 : -1);
            // }

            return params.toString();
        },

        consultar() {
            if (!this.checkPermission('zapercontrol_empresas_consultar')) {
                alert('Usuário sem permissão');
                return;
            }

            this.loading = true;
            this.dados = [];

            this.setFilters();

            const params = this.getParams();

            this.$http.get(`empresas?${params}`)
                .then(resp => {
                    this.pagination = resp.data.meta;
                    this.dados = resp.data.data;
                    this.loading = false;
                })
                .catch(() => {
                    alert('Não foi possivel concluir a operação', 'warning');
                }).finally(() => (this.loading = false));
        },

        abrirCardapio(route) {
            window.open(`${this.baseZaperMenu}/${route}`);
        },

        abrirWhatsapp(whats) {
            const phone = '55' + whats.replace(/\D/g, '');
            window.open(`https://api.whatsapp.com/send?phone=${phone}`);
        },

        novo() {
            window.open(`${this.baseZaperComandas}/register`);
        },

        editar(row) {
            if (!this.checkPermission('zapercontrol_empresas_historico_cobrancas_consultar')) {
                alert('Usuário sem permissão');
                return;
            }
            this.$router.push(`/financeiro/${row.id}`);
        },

        isDateOlderThan5Days(item) {
            if (!item.assinatura_id) {
                return false;
            }

            const date = this.moment(item.data_ultimo_pedido);
            const currentDate = this.moment();

            const daysDifference = currentDate.diff(date, 'days');

            return daysDifference >= 5;
        },

        excluir(id) {
            if (confirm('Deseja realmente excluir?')) {
                this.$http.delete(`empresa/${id}`).then(resp => {
                    const data = resp.data.data;
                    if (data.type == 'warning') {
                        alert(data.msg);
                        return;
                    }

                    alert('Excluido com sucesso');
                    this.consultar();
                }).catch(() => {
                    alert('Não foi possivel concluir a operação', 'warning');
                });
            }
        },

        emitirAssinaturaDialog(id) {
            this.edit = false;
            this.visible1 = true;
            this.empresaId = id;
        },

        testeGratisDialog(id) {
            this.visible3 = true;
            this.empresaId = id;
        },

        openModulesDialog(empresa) {
            this.visible4 = true;
            this.empresa = empresa;
        },

        emitirAssinatura() {
            this.visible1 = false;

            alert('Assinatura emitida!');

            this.consultar();
        },

        alterarAssinatura(id) {
            this.edit = true;
            this.visible1 = true;
            this.empresaId = id;
        },

        confirmInicioTesteGratis() {
            this.visible3 = false;

            alert('Teste grátis iniciado!');

            this.consultar();
        },

        async cancelarAssinatura(empresa) {
            if (!await this.$root.$confirm('Confirmação', 'Deseja realmente cancelar?', { color: 'red darken-1' })) {
                return;
            }

            this.$root.$emit('loading', true);
            this.$http.post('assinaturas/cancelar', { empresa }).then(resp => {
                const data = resp.data;
                if (data.type == 'warning') {
                    alert(data.msg);
                    return;
                }

                alert('Cancelamento efetuado com sucesso');
                this.consultar();
            })
            .catch(() => {
                alert('Não foi possivel concluir a operação', 'warning');
            }).finally(() => (this.$root.$emit('loading', false)));
        },

        async bloquearDesbloquear(empresa) {
            if (!await this.$root.$confirm('Confirmação', 'Confirmar ação?', { color: 'red darken-1' })) {
                return;
            }

            this.$root.$emit('loading', true);
            this.$http.post('empresa/bloquear-desbloquear', { empresa }).then(() => {
                alert('Ok');
                this.consultar();
            })
            .catch(() => {
                alert('Não foi possivel concluir a operação', 'warning');
            }).finally(() => (this.$root.$emit('loading', false)));
        },

        setFilters() {
            const filters = {
                page: this.pagination.current_page,
                search: this.search,
                somenteInadimplentes: this.somenteInadimplentes,
            };
            localStorage.setItem('zaper-control-emp-filters', JSON.stringify(filters));
        },

        getFilters() {
            const filters = JSON.parse(localStorage.getItem('zaper-control-emp-filters'));

            if (!filters) {
                return;
            }

            this.pagination.current_page = filters.page;
            this.search = filters.search;
            this.somenteInadimplentes = filters.somenteInadimplentes;
        },

        getSubscriptionColor({ last_charge_notification: charge }) {
            if (!charge) {
                return 'white';
            }

            if (['paid', 'settled', 'identified'].includes(charge.status.current)) {
                return 'green';
            }

            if (['new', 'waiting'].includes(charge.status.current)) {
                return 'blue';
            }

            if (['new'].includes(charge.status.current)) {
                return 'yellow';
            }

            if (['unpaid', 'refunded', 'contested', 'canceled', 'expired'].includes(charge.status.current)) {
                return 'red';
            }
        },
    },
}
</script>

<style scoped>
.red-color {
    color: red;
}
</style>
